.acc-tabs {
    // overflow: hidden;
    @media screen and (max-width: 575px) {
        margin: 0 -15px;
    }

        .tab {
        width: 100%;
        // overflow: hidden;
        margin-bottom: 12px;
        position: relative;

        &-title {
            font-family: "Bree Serif", serif;
            font-size: 1.75rem;
            display: flex;
            justify-content: center;
            padding: 0.5rem 1em;
            cursor: pointer;
            color: $white;
            position: relative;
            background-color: $orange;

            @media screen and (max-width: 575px) {
                font-size: 1.25rem;
            }

                &.active {
                .arrow {
                    transform: rotate(-45deg);
                    top: 24px;

                    @media screen and (max-width: 575px) {
                        top: 21px;
                    }
                }
            }

            /* Icon */
            &:hover {
                text-decoration: underline;
            }

            .arrow {
                position: absolute;
                border: 4px solid $white;
                width: 18px;
                height: 18px;
                transform: rotate(135deg);
                border-bottom: 0;
                border-left: 0;
                transition: all .35s;
                right: 24px;
                top: 15px;

                @media screen and (max-width: 575px) {
                    width: 14px;
                    height: 14px;
                    top: 13px;
                }
            }
        }
        &-content {
            display: none;
            padding: 15px 0;

            @media screen and (max-width: 575px) {
                padding: 15px;
            }

            .ending,
            .introduction {
                margin-bottom: 1rem;

                h4 {
                    font-family: $font-family-serif;
                    color: $orange;
                    font-size: 1.2em;
                    margin: 0 0 3px;
                }
            }
        }

    }

}
