// Typography
$font-family-sans-serif: 'Hind', sans-serif;
$font-family-serif: 'Bree Serif', serif;
$font-size-base: 1rem;
$line-height-base: 1.4;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #FF6B14;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$white : #FFF;
$gray: #B6C0C6;
$dark-gray: #999;
$black: #000; // text

$orange-light: #FDA677;

$warning: $orange;

