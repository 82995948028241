footer {
    background-color: $orange;
    color: $white;
    margin-bottom: 2rem;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: $black;
        }
    }

    .white-row {
        background-color: $white;
        text-align: center;
    }

    .socials_widget {
        display: inline-block;

        a {
            display: block;
            float: left;
            margin: 20px 20px 20px 0;
        }

        .fa {
            background: #000 !important;
            color: #fff;
            width: 29px;
            height: 29px;
            display: block;
            float: left;
            line-height: 29px;
            border-radius: 100%;
            margin: 0;
            font-size: 20px;
            text-align: center;
        }
    }

    #footer-menu {
        padding: 0.75rem 0 1rem;
        margin: 0 auto;
        text-align: center;

        a {
            text-decoration: underline;
        }
    }

    /* footer styling */
    #footer .footer-icon-row-mobile,
    #footer .footer-icon-row {
        background-color: $orange;
        overflow: visible;

        img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }

    #footer .footer-icon-row {
        display: none;
    }

    #footer .footer-icon-row-mobile {
        height: 94px;
    }

    @media screen and (min-width: 567px) {
        #footer .footer-icon-row {
            display: block;
            height: 100px;
        }

        #footer .footer-icon-row-mobile {
            display: none;
        }
    }

    @media screen and (min-width: 767px) {
        #footer .footer-icon-row {
            height: 125px;
        }

    }

    @media screen and (min-width: 992px) {
        #footer .footer-icon-row {
            height: 164px;
        }
    }

    @media screen and (min-width: 1200px) {
        #footer .footer-icon-row {
            height: 198px;
        }
    }
}
