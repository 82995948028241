#share-result {
    max-width: 600px;

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;

        .label {
            margin: 20px 0;
        }
    }

    .label {
        font-family: $font-family-serif;
        font-size: 2.2rem;
        line-height: 33px;

        @media screen and (max-width: 575px) {
            font-size: 1.5rem;
            line-height: 1em;
        }
    }

    a {
        display: block;
        float: left;
        margin: 20px 20px 20px 0;
    }

    .icons {
        position: relative;
        padding-right: 65px;

        .fa {
            background: $orange !important;
            color: #ffffff;
            width: 40px;
            height: 40px;
            display: block;
            float: left;
            line-height: 40px;
            border-radius: 100%;
            margin: 0;
            font-size: 26px;
            text-align: center;

            &:hover {
                background-color: #000000;
            }
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            border: 12px solid $orange;
            width: 45px;
            height: 45px;
            border-bottom: 0;
            border-left: 0;
            transform: rotate(45deg);
            right: 18px;
            top: 18px;
        }
    }

}

#email-form {
    background-color: #8BA3B7;
    padding: 1rem;
    color: $white;
    margin-bottom: 3rem;

    @media screen and (max-width: 575px){
        margin-left: -15px;
        margin-right: -15px;
    }

    @media screen and (min-width: 960px) {
        padding: 2rem 2rem 1rem;

        .top {
            display: flex;
        }

        .left {
            width: 35%;
        }
    }

    .left {
        font-family: $font-family-serif;
        font-size: 22px;
        margin-bottom: 1rem;
    }

    ul {
        list-style-type: none;
        color: #000000;
        margin-bottom: 2rem;

        li {
            position: relative;
            margin-bottom: 1rem;

            &:before {
                content: '';
                display: block;
                position: absolute;
                border: 4px solid $white;
                width: 21px;
                height: 13px;
                border-bottom: 0;
                border-left: 0;
                transform: rotate(135deg);
                left: -36px;
                top: -1px;
            }
        }
    }

    .form {
        margin-top: 2rem;

        @media screen and (max-width: 960px){
            margin-top: 73px;
        }

        @media screen and (min-width: 960px) {
            .form-field {
                float: left;
                width: 33%;
            }
        }
    }

    .form-field {
        margin-bottom: 20px;

        &.field2 {
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                background-image: url('/img/boy-skating.png');
                width: 69px;
                height: 63px;
                background-size: contain;
                top: -54px;
                right: -3px;

                @media screen and (max-width: 960px){
                    top: -112px;
                    right: 15px;
                }
            }
        }

        & > input {
            width: 95%;
            max-width: 95%;
            display: inline-block;
            background: #fff;
            border-radius: 0;
            padding: 7px 14px;
            height: 40px;
            outline: none;
            font-size: 14px;
            font-weight: 300;
            margin: 0;
            box-shadow: none;
        }
    }

    input {
        border: 0;

        &[type=submit] {
            width: 100%;
            color: #ffffff;
            background-color: #000000;
            border: 0;
            padding: 0;
            text-align: center;
            font-family: "Bree Serif", serif !important;
            text-transform: initial;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .alert {
        background-color: $white;
        color: $black;
        border: 0;
        border-radius: 0;
    }

}
