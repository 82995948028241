.hour-indicator {
    font-family: $font-family-serif;
    width: 40px;
    background-color: $orange;
    text-align: center;
    line-height: 2.2rem;
    margin-right: 10px;
    color: $white;
    flex: initial;

}

ul.result-list {
    padding-left: 0;

    li {
        display: flex;
        list-style-type: none;
        margin-bottom: 7px;
    }

    .subject {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & > div {
            margin-top: 3px;
        }
    }
}

.orange {
    color: $orange;
}

h3 .orange {
    padding-left: 3px;
}

.container.results {
    margin-top: 3rem;

    .archetype-header {
        background-color: $orange;
        color: $white;
        font-family: $font-family-serif;
        text-align: center;
        font-size: 2rem;
        padding: 4px 10px 12px;
        margin-bottom: 1.5rem;
        line-height: 1.1;
    }

    .a-typering {
        font-family: $font-family-serif;
        font-size: 1.6rem;
        line-height: 1.1;
        margin: 10px 0;
    }

    img {
        width: 100%;
        max-width: 450px;
        height: auto;

        &.energy-icon {
            width: auto;
            margin: -3px 0 0 4px;
        }
    }

    #archetype-tile {
        margin-bottom: 2rem;
        text-align: center;

        @media screen and (min-width: 768px){
            max-width: 40%;
            float: right;
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }
}

#resultsapp {
    margin-bottom: 5rem;
}

$red: #FF364C;
$blue-light: #F0F8FF;

@keyframes move {
    from {right: 18px;}
    to {right: 8px;}
}

#tips a:hover {
    text-decoration: none;
}

.woww_link {
    color: #000;
    background-color: $blue-light;
    margin-bottom: 1rem;
    position: relative;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > * {
        margin-left: 20px;
        margin-right: 56px;
        line-height: 1.2;
    }

    .title {
        font-weight: bold;
        padding: 5px 0 2px;
    }

    .description {
        padding-bottom: 5px;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        border: 8px solid $red;
        width: 34px;
        height: 34px;
        transform: rotate(45deg);
        border-bottom: 0;
        border-left: 0;
        right: 18px;
        top: 22px;
    }

    &:hover {
        text-decoration: none;

        &:after {
            animation-name: move;
            animation-duration: 0.125s;
            animation-iteration-count: 2;
            animation-direction: alternate;
            animation-timing-function: ease;
        }
    }
}

