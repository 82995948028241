header {
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 255px solid $orange;
        border-left: 1030px solid $white;
        right: 0;
        bottom: 0;

        @media screen and (min-width: 1200px) {
            border-left-width: 1214px;
            border-bottom-width: 291px;
        }

        @media screen and (min-width: 1450px) {
            border-left-width: 76.8vw;
            border-bottom-width: calc(31/145 * 96vw);
        }
    }

    #logo {
        position: relative;
        text-align: center;

        @media screen and (min-width: 768px) {
            height: 291px;

            img {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }

    #tagline {
        text-align: center;
        padding: 1rem 0 0;
        font-family: $font-family-serif;
        font-size: 15vw;
        line-height: 15vw;

        a {
            text-decoration: none;
            color: inherit;
        }

        @media screen and (min-width: 465px) {
            font-size: 70px;
            line-height: 70px;
        }

        @media screen and (min-width: 768px) {
            padding-bottom: 1rem;
        }

        @media screen and (min-width: 992px) {
            font-size: 85px;
            line-height: 85px;
        }

        .tag-wrapper {
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 20px;
        }
    }
}
