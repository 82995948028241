main {
    flex-grow: 1;
}

.energy-question,
.control-button {
    font-family: $font-family-serif;
}

.energy-question {
    font-size: 1.5rem;

    .header {
        color: $orange;
    }
}

.carousel-item .index {
    font-family: $font-family-serif;
}

#app #question-slider-content {
    .control-button {
        font-size: 1.75rem;
        line-height: 1.72rem;
    }

    .energy-question {
        font-size: 1.5rem;

        .header {
            color: $orange;
        }
    }
}

.content-top {
    padding-top: 40px;

    h3 {
        font-size: 1.3rem;
    }

    .opening {
        margin-bottom: 2rem;
    }
}

.start-link {
    text-align: center;

    a {
        text-decoration: none;

        &:hover .start-button {
            background-color: #000000;
        }
    }
}

.start-button {
    background-color: $orange;
    font-family: $font-family-serif;
    font-size: 2rem;
    color: $white;
    padding: 1rem 2rem;
    margin: 3rem 0 4rem;
    display: inline-block;
}

.content-top .opening {
    h4 {
        font-size: 1em;
        font-weight: bold;
        margin: 0;
    }
}

.article {
    margin-bottom: 3rem;

    h2 {
        font-size: 28px;
        margin-bottom: 1.5rem;
    }

    .subtitle {
        margin-bottom: 7px;
        font-family: $font-family-serif;
        font-size: 18px;
        color: $orange;
    }

    .article-content .featured-image {
        margin-bottom: 10px;

        @media screen and (min-width: 768px) {
            float: right;
            margin-left: 16px;
            width: 58%;
            margin-bottom: 3px;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }
}
