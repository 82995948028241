// Variables
@import 'variables';

// Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
//@import "~bootstrap/scss/nav";
//@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
//@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

//fontawesom
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/variables';
@import '~@fortawesome/fontawesome-free/scss/mixins';
@import '~@fortawesome/fontawesome-free/scss/core';
@import '~@fortawesome/fontawesome-free/scss/larger';
//@import '~@fortawesome/fontawesome-free/scss/fixed-width';
//@import '~@fortawesome/fontawesome-free/scss/list';
//@import '~@fortawesome/fontawesome-free/scss/bordered-pulled';
//@import '~@fortawesome/fontawesome-free/scss/animated';
//@import '~@fortawesome/fontawesome-free/scss/rotated-flipped';
//@import '~@fortawesome/fontawesome-free/scss/stacked';
@import '~@fortawesome/fontawesome-free/scss/icons';
@import '~@fortawesome/fontawesome-free/scss/screen-reader';


body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}

h1, h2, h3, .header {
    font-family: $font-family-serif;
}

#clocks_row {
    text-align: center;
    margin-bottom: 4rem;

    img {
        max-width: 100%;
    }
}

@import "header";
@import "main";
@import "footer";
@import "results";
@import "accordion";
@import 'share';

.clearfix {
    clear: both;
}
